document.addEventListener('DOMContentLoaded', function() {
  // Vérifiez si la div spécifique existe sur la page
  const addressFieldContainer = document.querySelector('[data-page="bicycles-new"]');

  if (addressFieldContainer) {
    const addressInput = document.getElementById('location-input');
    const warningDiv = document.getElementById('address-warning');

    if (addressInput) {
      addressInput.addEventListener('focus', function() {
        warningDiv.style.display = 'block';
      });

      addressInput.addEventListener('input', function() {
        warningDiv.style.display = 'block';
      });

			// Le message disparaît quand une adresse est sélectionnée (à ajouter dans votre callback de sélection d'adresse)
    // Dans votre fonction de callback d'autocomplete, ajoutez :
    // warningDiv.style.display = 'none';
    }
  }
});
