let activeMarker = null;
let markers = [];
export let map;
window.loadBicyclesDataTimeout = null;
let mode = 'all';

import { loadBicyclesData as loadBicyclesDataMap } from './indexMap';
import { loadBicyclesData as loadBicyclesDataCity } from './indexCity';
import { loadBicyclesData as loadBicyclesDataCategory } from './indexCategory';
import { loadBicyclesData as loadBicyclesDataLocation } from './indexLocation';

function getLoadBicyclesDataFunction() {
  const categoryMap = document.getElementById('category-map');
  const cityMap = document.getElementById('city-map');
  const locationMap = document.getElementById('location-map');
  const indexMap = document.getElementById('index-map');
  
  if (categoryMap) {
    return loadBicyclesDataCategory; // Si l'élément avec l'ID 'category-map' est trouvé
  } else if (cityMap) {
    return loadBicyclesDataCity; // Si l'élément avec l'ID 'city-map' est trouvé
  } else if (locationMap) {
    return loadBicyclesDataLocation; // Si l'élément avec l'ID 'location-map' est trouvé
  } else if (indexMap) {
    return loadBicyclesDataMap; // Si l'élément avec l'ID 'index-map' est trouvé
  } else {
    console.log('Carte non trouvée !');
    return null;
  }
}

let loadBicyclesData = getLoadBicyclesDataFunction();

export function setupAutocomplete(mapInstance) {
  const input = document.getElementById('city-input');
  if (!input) return;

  const autocomplete = new google.maps.places.Autocomplete(input, { types: ['(cities)'] });
  autocomplete.bindTo('bounds', mapInstance);

  autocomplete.addListener('place_changed', function() {
    const place = autocomplete.getPlace();
    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    if (place.geometry.viewport) {
      const expandedBounds = expandBounds(place.geometry.viewport, 10000); // 10 km buffer
      mapInstance.fitBounds(expandedBounds);
    } else {
      const latLng = place.geometry.location;
      const expandedBounds = expandBoundsByLatLng(latLng, 10000); // 10 km buffer around the location
      mapInstance.fitBounds(expandedBounds);
      mapInstance.setZoom(12); // Adjust zoom level appropriately
    }

    const loadMoreButton = document.getElementById('load-more');
    if (loadMoreButton) {
      loadMoreButton.setAttribute('data-page', 2);
    }
    resetAndShowLoadMoreButton();
    loadBicyclesData();
  });
}

export function loadMarkers(bicycles) {

  if (!map) {
    console.error('Map is not initialized before calling loadMarkers.');
    return;
  }

  const markersMap = {}; // Object to store markers by position
  const radius = 0.0018; // Approximate radius in degrees for 10 meters

  markers.forEach(marker => {
    marker.setMap(null);
  });
  markers = [];

  let infowindow = new google.maps.InfoWindow();

  bicycles.forEach(bicycle => {
    const position = { lat: bicycle.latitude, lng: bicycle.longitude };
    // Round the position to group nearby locations
    const roundedPosition = {
      lat: Math.round(position.lat / radius) * radius,
      lng: Math.round(position.lng / radius) * radius
    };
    const key = `${roundedPosition.lat},${roundedPosition.lng}`;

    // Initialize marker array for this position if not already
    if (!markersMap[key]) {
      markersMap[key] = [];
    }
    markersMap[key].push(bicycle);

    const numberOfBicycles = markersMap[key].length;

    // Find existing marker for this rounded position
    const existingMarker = markers.find(marker =>
      Math.round(marker.getPosition().lat() / radius) * radius === roundedPosition.lat &&
      Math.round(marker.getPosition().lng() / radius) * radius === roundedPosition.lng
    );

    if (!existingMarker) {
      const marker = new google.maps.Marker({
        position: roundedPosition,
        map: map,
        title: numberOfBicycles > 1 ? `${numberOfBicycles}` : bicycle.model,
        label: numberOfBicycles > 1 ? {
          text: numberOfBicycles.toString(),
          color: 'black',
          fontSize: '20px',
          fontWeight: 'bold'
        } : undefined,
        icon: {
          url: '/assets/logo_maps.png',
          scaledSize: new google.maps.Size(22.8, 35)
        }
      });

      marker.addListener('click', () => {
        if (activeMarker) {
          activeMarker.setIcon({
            url: '/assets/logo_maps.png',
            scaledSize: new google.maps.Size(22.8, 35)
          });
        }

        marker.setIcon({
          url: '/assets/logo_maps_beige.png',
          scaledSize: new google.maps.Size(22.8, 35)
        });
        activeMarker = marker;

        infowindow.close();

        const bicyclesAtPosition = markersMap[key];
        let contentString = '';

        if (bicyclesAtPosition.length > 1) {
          const bike = bicyclesAtPosition[0];
          const card = document.querySelector(`.annonce-item[data-bicycle-id="${bike.id}"]`);
          const pricePerHourWithFees = card ? card.getAttribute('data-price-per-hour-with-fees') : bike.price_per_hour;
          const pricePerWeekWithFees = card ? card.getAttribute('data-price-per-week-with-fees') : bike.price_1_week;

          contentString = `
          <div style="height: auto; border-radius: 10px; overflow: hidden; position: relative; background-color: white; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);">
            <!-- Carousel pour plusieurs vélos -->
            <div id="carouselAdsBicycles" class="carousel slide" style="height: 70%; overflow: hidden;">
              <div class="carousel-inner" style="border-radius:0px;">
                ${bicyclesAtPosition.map((bike, index) => {
                  return `
                  <div class="carousel-item ${index === 0 ? 'active' : ''}" style="height: 100%;">
                    <img src="${bike.image_url || '/assets/icone_velo.png'}" class="d-block w-100" style="object-fit: cover; width: 190px; height: 130px; border-radius: 10px;" alt="${bike.model}">
                  </div>
                `;}).join('')}
              </div>
              <div class="carousel-indicators" style="bottom: -15px; text-align: center;">
                ${bicyclesAtPosition.map((_, index) => `
                  <button type="button" 
                          data-bs-target="#carouselAdsBicycles" 
                          data-bs-slide-to="${index}" 
                          class="${index === 0 ? 'active' : ''}" 
                          aria-current="true" 
                          aria-label="Slide ${index + 1}" 
                          style="border-radius: 50%; width: 10px; height: 10px; 
                                border: none; padding: 0; margin: 0 2px;">
                  </button>
                `).join('')}
              </div>
              <button class="carousel-control-prev" style="border-radius: 0px; height: 30px; width: 30px; position: absolute; top: 50%; transform: translateY(-50%); left: 5px;" type="button" data-bs-target="#carouselAdsBicycles" data-bs-slide="prev">
                <span class="carousel-control-prev-icon white-arrow" aria-hidden="true"></span>
              </button>
              <button class="carousel-control-next" style="border-radius: 0px; height: 30px; width: 30px; position: absolute; top: 50%; transform: translateY(-50%); right: 5px;" type="button" data-bs-target="#carouselAdsBicycles" data-bs-slide="next">
                <span class="carousel-control-next-icon white-arrow-next" aria-hidden="true"></span>
              </button>
            </div>
            <!-- Section d'informations (30%) -->
            <div style="height: 30%; padding: 10px; background-color: white; box-sizing: border-box;">
              <h5 id="bicycle-model" style="margin: 0; font-size: 16px;">${bicyclesAtPosition[0].model}</h5>
              <p id="bicycle-location" style="margin: 2px 0;">${bicyclesAtPosition[0].city}, ${bicyclesAtPosition[0].postal_code}</p>
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <p id="bicycle-price_day" style="margin: 2px 0;"><b>${pricePerHourWithFees} &euro;</b> / jour</p>
                <p id="bicycle-price_week" style="margin: 2px 0; margin-left: 10px;"><b>${pricePerWeekWithFees} &euro;</b> / sem</p>
              </div>
              <a id="bicycle-link" href="/bicycles/${bicyclesAtPosition[0].slug}" class="btn btn-secondary btn-sm d-flex justify-content-center" style="margin-bottom: 0px;" aria-label="Voir les détails du vélo" data-turbo="false">Voir</a>
            </div>
            <button class="close-infowindow" style="position: absolute; top: 5px; right: 5px; border: none; background: none; font-size: 20px; cursor: pointer;">&times;</button>
          </div>
        `;

        infowindow.setContent(contentString);
        infowindow.open({ anchor: marker, map: map, shouldFocus: false });

        setTimeout(() => {
          const carousel = document.getElementById('carouselAdsBicycles');
          if (carousel) {
            console.log('Carrousel trouvé');
            carousel.addEventListener('slide.bs.carousel', function (event) {
              const index = event.to; // Get index of the new slide
              const activeBike = bicyclesAtPosition[index];
              const activeCard = document.querySelector(`.annonce-item[data-bicycle-id="${activeBike.id}"]`);
              const activePricePerHourWithFees = activeCard ? activeCard.getAttribute('data-price-per-hour-with-fees') : activeBike.price_per_hour;
              const activePricePerWeekWithFees = activeCard ? activeCard.getAttribute('data-price-per-week-with-fees') : activeBike.price_1_week;
        
              // Mettre à jour les informations en fonction du slide actif
              document.getElementById('bicycle-model').textContent = activeBike.model;
              document.getElementById('bicycle-location').textContent = `${activeBike.city}, ${activeBike.postal_code}`;
              document.getElementById('bicycle-price_day').innerHTML = `<b>${activePricePerHourWithFees} &euro;</b> / jour`;
              document.getElementById('bicycle-price_week').innerHTML = `<b>${activePricePerWeekWithFees} &euro;</b> / sem`;
              document.getElementById('bicycle-link').setAttribute('href', `/bicycles/${activeBike.slug}`);
            });
          } else {
            console.warn("Le carrousel n'a pas été trouvé");
          }
        }, 100); // Ajuste la valeur de timeout si nécessaire

        } else {
          // Single bicycle, use image with overlay
          const bike = bicyclesAtPosition[0];
          const card = document.querySelector(`.annonce-item[data-bicycle-id="${bike.id}"]`);
          const pricePerHourWithFees = card ? card.getAttribute('data-price-per-hour-with-fees') : bike.price_per_hour;
          const pricePerWeekWithFees = card ? card.getAttribute('data-price-per-week-with-fees') : bike.price_1_week;

          contentString = `
            <div style="height: auto; border-radius: 10px; overflow: hidden; position: relative; background-color: white; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);">
              <!-- Image covering 70% -->
              <div style="height: 70%; overflow: hidden;">
                <img src="${bike.image_url || '/assets/icone_velo.png'}" class="d-block w-100" style="object-fit: cover; width: 190px; height: 130px; border-radius: 10px;" alt="${bike.model}">
              </div>
              <!-- Info section (30%) -->
              <div style="height: 30%; padding: 10px; background-color: white; box-sizing: border-box;">
                <h5 style="margin: 0; font-size: 16px;">${bike.model}</h5>
                <p style="margin: 2px 0;">${bike.city}, ${bike.postal_code}</p>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <p style="margin: 2px 0;"><b>${pricePerHourWithFees} &euro;</b> / jour</p>
                  <p style="margin: 2px 0;"><b>${pricePerWeekWithFees} &euro;</b> / sem</p>
                </div>  
                <a href="/bicycles/${bike.slug}" class="btn btn-secondary btn-sm d-flex justify-content-center" style="margin-bottom: 0px;" aria-label="Voir les détails du vélo" data-turbo="false">Voir</a>
              </div>
              <button class="close-infowindow" style="position: absolute; top: 5px; right: 5px; border: none; background: none; font-size: 20px; cursor: pointer;">&times;</button>
            </div>
          `;
        }

        infowindow.setContent(contentString);
        infowindow.open({ anchor: marker, map: map, shouldFocus: false });

        setTimeout(() => {
          const closeButton = document.querySelector('.close-infowindow');
          if (closeButton) {
            closeButton.addEventListener('click', () => {
              infowindow.close();
              if (activeMarker) {
                activeMarker.setIcon({
                  url: '/assets/logo_maps.png',
                  scaledSize: new google.maps.Size(22.8, 35)
                });
                activeMarker = null;
              }
            });
          }
        }, 100);

        google.maps.event.clearListeners(map, 'bounds_changed');
        setTimeout(() => {
          map.addListener('bounds_changed', debounce(handleBoundsChanged, 500));
        }, 1000);
      });

      markers.push(marker);
    } else {
      existingMarker.setLabel({
        text: numberOfBicycles.toString(),
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold'
      });
    }
  });  
   
    map.addListener('click', () => {
      infowindow.close();
      if (activeMarker) {
        activeMarker.setIcon({
          url: '/assets/logo_maps.png',
          scaledSize: new google.maps.Size(22.8, 35)
        });
        activeMarker = null;
      }
  });
}

export function initIndexMap({ mapElementId, defaultZoom }) {
  console.log("Initializing Map");

  if (typeof google === 'undefined') {
    // Attendre que l'API soit chargée
    window.initMap = () => {
      initIndexMap({ mapElementId, defaultZoom });
    };
    return;
  }

  if (Array.isArray(markers) && markers.length > 0) {
    markers.forEach(marker => marker.setMap(null));
    markers = [];
  }

  const mapElement = document.getElementById(mapElementId);
  if (!mapElement) {
    console.error(`Map element with ID ${mapElementId} not found.`);
    return;
  }

  const cityLat = parseFloat(mapElement.dataset.latitude) || null;
  const cityLng = parseFloat(mapElement.dataset.longitude) || null;

  const customMapStyles = [
    { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#000000' }] }, // Noms des villes en noir
    { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#92bae9' }] // Bleu pour les mers et océans
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'landscape.natural.landcover', // Utilisation de beige pour les zones naturelles couvertes
      elementType: 'geometry',
      stylers: [{ color: '#f5f1ea' }]
    },
    {
      featureType: 'landscape.natural.terrain', // Utilisation de beige pour le terrain naturel
      elementType: 'geometry',
      stylers: [{ color: '#f5f1ea' }]
    }
  ];

  let zoomLevel = defaultZoom;
  if (window.innerWidth <= 768) {
    zoomLevel = defaultZoom - 1; // Réduire légèrement le zoom sur mobile
  }

  const mapCenter = cityLat && cityLng
    ? { lat: cityLat, lng: cityLng } // Coordonnées spécifiques à une ville
    : { lat: 47.081012, lng: 2.398782 };

  if (map) {
    map = null;
  }

  map = new google.maps.Map(mapElement, {
    center: mapCenter,
    zoom: zoomLevel,
    styles: customMapStyles,
  });

  setupAutocomplete(map);

  if (loadBicyclesData) {
    map.addListener('bounds_changed', function() {
      if (window.loadBicyclesDataTimeout) {
        clearTimeout(window.loadBicyclesDataTimeout);
      }
      window.loadBicyclesDataTimeout = setTimeout(loadBicyclesData, 500);
    });
  }
  toggleDateAndDurationFields(mode === 'all');

  map.addListener('bounds_changed', debounce(handleBoundsChanged, 500));
}

function handleBoundsChanged(loadBicyclesData) {
  const currentBounds = map.getBounds();
  console.log("Bounds changed");

  if (boundsChangedSignificantly(currentBounds)) {
    const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    lastBounds = currentBounds;
    if (loadBicyclesData) {
      loadBicyclesData(); // Appeler la fonction passée en paramètre
    }
    resetAndShowLoadMoreButton();
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const isIndexPage = document.getElementById("page-index");

  // Partie spécifique à `page-index` : Gestion du scroll horizontal
  if (isIndexPage) {
    handleHorizontalScroll();
  }

  // Partie commune à toutes les pages
  const toggleButtonContainer = document.getElementById("toggleButtonContainer");
  if (toggleButtonContainer) {
    handleMapAndListToggle();
  }
});

// Fonction pour gérer le défilement horizontal sur `page-index`
function handleHorizontalScroll() {
  // Marquee duplication
  const marqueeContent = document.querySelector(".logos-marquee-content");
  if (marqueeContent) {
    const clone = marqueeContent.cloneNode(true);
    document.querySelector(".logos-marquee").appendChild(clone);
  }

  // Horizontal scrolling for categories
  const container = document.querySelector(".categories-container");
  if (container) {
    let isDown = false;
    let startX;
    let scrollLeft;

    container.addEventListener("mousedown", (e) => {
      isDown = true;
      container.classList.add("active");
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener("mouseleave", () => {
      isDown = false;
      container.classList.remove("active");
    });

    container.addEventListener("mouseup", () => {
      isDown = false;
      container.classList.remove("active");
    });

    container.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2; // Scroll faster
      container.scrollLeft = scrollLeft - walk;
    });

    container.scrollLeft = 0;
  }
}

// Fonction pour gérer l'affichage/masquage de la carte et de la liste (commune à toutes les pages)
function handleMapAndListToggle() {
  const bicyclesList = document.getElementById("bicycles-list");
  const mapContainer = document.getElementById("map-container");
  const loadMoreButton = document.getElementById("load-more");
  const listButton = document.getElementById("listButton");
  const mapButton = document.getElementById("mapButton");

  if (bicyclesList && mapContainer) {
    // Initial display setup
    if (window.innerWidth >= 1023) {
      bicyclesList.classList.remove("hidden-map");
      if (loadMoreButton) {
        loadMoreButton.classList.remove("hidden-map");
      }
    } else {
      bicyclesList.classList.add("hidden-map");
      mapContainer.classList.remove("hidden-map");
      if (loadMoreButton) {
        loadMoreButton.classList.add("hidden-map");
      }
    }

    // Button actions
    const showMap = () => {
      mapContainer.classList.remove("hidden-map");
      bicyclesList.classList.add("hidden-map");
      mapButton?.classList.add("selected");
      listButton?.classList.remove("selected");
      if (loadMoreButton) loadMoreButton.classList.add("hidden-map");
      mapContainer.scrollIntoView({ behavior: "smooth" });
    };

    const showList = () => {
      bicyclesList.classList.remove("hidden-map");
      mapContainer.classList.add("hidden-map");
      listButton?.classList.add("selected");
      mapButton?.classList.remove("selected");
      if (loadMoreButton) loadMoreButton.classList.remove("hidden-map");
    };

    listButton?.addEventListener("click", showList);
    mapButton?.addEventListener("click", showMap);
  }
}

let lastBounds = null;

export function resetAndShowLoadMoreButton() {
  const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    loadMoreButton.setAttribute('data-page', 2); // Réinitialiser la pagination à la première page
    loadMoreButton.style.display = 'block'; // Réafficher le bouton
  }
}

export function expandBounds(bounds, distance) {
  // Convert distance from meters to latitude/longitude degrees
  const latChange = distance / 111320;
  const lngChange = distance / (111320 * Math.cos(Math.PI * bounds.getCenter().lat() / 180));

  const sw = new google.maps.LatLng(bounds.getSouthWest().lat() - latChange, bounds.getSouthWest().lng() - lngChange);
  const ne = new google.maps.LatLng(bounds.getNorthEast().lat() + latChange, bounds.getNorthEast().lng() + lngChange);

  return new google.maps.LatLngBounds(sw, ne);
}

export function expandBoundsByLatLng(latLng, distance) {
  const latChange = distance / 111320;
  const lngChange = distance / (111320 * Math.cos(Math.PI * latLng.lat() / 180));

  const sw = new google.maps.LatLng(latLng.lat() - latChange, latLng.lng() - lngChange);
  const ne = new google.maps.LatLng(latLng.lat() + latChange, latLng.lng() + lngChange);

  return new google.maps.LatLngBounds(sw, ne);
}

export function boundsChangedSignificantly(newBounds) {
  if (!lastBounds) return true; // S'il n'y a pas de dernières limites, retourner true

  const changeThreshold = 10000; // Seuil de changement, ajustez selon les besoins
  const oldCenter = lastBounds.getCenter();
  const newCenter = newBounds.getCenter();

  if (google.maps.geometry && google.maps.geometry.spherical) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(oldCenter, newCenter);
    return distance > changeThreshold
  } else {
    console.error("Google Maps Geometry library is not loaded.");
    return false;
  }
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function toggleDateAndDurationFields(enable) {
  const startDateInput = document.getElementById('start-date-input');
  const endDateInput = document.getElementById('end-date-input');

  if (startDateInput && endDateInput) {
    startDateInput.disabled = !enable;
    endDateInput.disabled = !enable;
  }
}

function getCurrentPage() {
  const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    return parseInt(loadMoreButton.getAttribute('data-page')) || 1;
  }
  return 1; // Par défaut, retourne la première page si aucun bouton n'est trouvé
}

function getCurrentFilters() {
  const categoryInput = document.getElementById('category-input');
  const startDateInput = document.getElementById('start-date-input');
  const endDateInput = document.getElementById('end-date-input');

  const filters = {};

  if (categoryInput) {
    filters.category = categoryInput.value;
  }

  if (startDateInput) {
    filters.startDate = startDateInput.value;
  }

  if (endDateInput) {
    filters.endDate = endDateInput.value;
  }

  return filters;
}

function savePageState(bicycleId) {
  const currentState = {
    scrollTop: window.scrollY,
    currentPage: getCurrentPage(),
    filters: getCurrentFilters(),
    bicycleId: bicycleId // Ajoutez l'identifiant de l'annonce ici
    // Ajoutez d'autres informations d'état selon vos besoins
  };

  // Stockez l'état dans sessionStorage
  sessionStorage.setItem('savedPageState', JSON.stringify(currentState));
}

window.addEventListener('popstate', function(event) {
  // Restaurez l'état de la page depuis sessionStorage
  const savedState = JSON.parse(sessionStorage.getItem('savedPageState'));

  if (savedState) {
    // Restaurez la position du défilement
    window.scrollTo(0, savedState.scrollTop);

    // Restaurez la pagination et les filtres
    setCurrentPage(savedState.currentPage);
    applyFilters(savedState.filters);

    if (savedState.bicycleId) {
      const annonceItem = document.querySelector(`.annonce-item[data-bicycle-id="${savedState.bicycleId}"]`);
      if (annonceItem) {
        // Faites quelque chose avec l'annonce restaurée si nécessaire
      }
    }

    // Effacez l'état sauvegardé pour éviter de le réutiliser par erreur
    sessionStorage.removeItem('savedPageState');
  }
});

document.addEventListener('click', function(event) {
  // Vérifie si le clic est sur un élément qui représente une annonce
  const isAnnonceClick = event.target.closest('.annonce-item');
  
  if (isAnnonceClick) {
    const bicycleId = isAnnonceClick.dataset.bicycleId;
    // Vous pouvez maintenant enregistrer l'état actuel de la page
    savePageState(bicycleId);
  }
});

export function buildBoundsParams() {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return `ne_lat=${ne.lat()}&ne_lng=${ne.lng()}&sw_lat=${sw.lat()}&sw_lng=${sw.lng()}`;
}