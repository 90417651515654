import { buildBoundsParams, initIndexMap, toggleDateAndDurationFields, loadMarkers } from "./index";

let markers = [];
import { map } from './index.js'; // Importer map de index.js
let mode = 'all';
let searchParams = null;
let allBicycles = [];

let isSearchFormSubmitted = false;

document.addEventListener('DOMContentLoaded', function() {
  const searchForm = document.getElementById('search-city-form');
  if (searchForm) {
    searchForm.addEventListener('submit', function(event) {
      const startDate = document.getElementById('start-date-input').value;
      const endDate = document.getElementById('end-date-input').value;
      const category = document.getElementById('category-input').value;
      const spinner = document.getElementById('loading-spinner');
      spinner.style.display = 'flex';

      // Vérifiez d'abord si les dates sont renseignées
      if (mode === 'all') {
        // Aucune date ni catégorie
        if (!startDate && !endDate && !category) {
          event.preventDefault(); // Empêche la soumission du formulaire
          alert('Veuillez renseigner les dates ou sélectionner une catégorie.');
          spinner.style.display = 'none';
          return; // Stoppe l'exécution de la fonction ici
        }

        // Une seule date renseignée
        if ((startDate && !endDate) || (!startDate && endDate)) {
          event.preventDefault(); // Empêche la soumission du formulaire
          alert('Veuillez renseigner à la fois la date de début et la date de fin.');
          spinner.style.display = 'none';
          return; // Stoppe l'exécution de la fonction ici
        }
      }

      // Empêche la soumission normale du formulaire
      event.preventDefault();

      // Mettez à jour l'état de soumission du formulaire
      if (mode === 'all') {
        isSearchFormSubmitted = true;
      } else {
        isSearchFormSubmitted = false;
      }

      // Traite les données du formulaire
      const formData = new FormData(searchForm);
      searchParams = new URLSearchParams(formData).toString();
      mode = mode === 'all' ? 'filtered' : 'all';
      searchForm.querySelector('input[type="submit"]').value = mode === 'all' ? "Rechercher" : "Tout afficher";

      // Définir isSearchFormSubmitted à true si le mode est 'filtered'
      isSearchFormSubmitted = mode === 'filtered';

      sessionStorage.setItem('startDate', startDate);
      sessionStorage.setItem('category', category);
      sessionStorage.setItem('endDate', endDate);

      loadBicyclesData().then(() => {
        spinner.style.display = 'none'; // Masque le spinner après le chargement
      }).catch(error => {
        console.error('Erreur lors du chargement des vélos :', error);
        spinner.style.display = 'none'; // Masque le spinner même en cas d'erreur
      });
      
      toggleDateAndDurationFields(mode === 'all');
    });
  }
});  

export async function loadBicyclesData() {
  const spinner = document.getElementById('loading-spinner');
  spinner.style.display = 'flex';
  allBicycles = [];
  const startDate = document.getElementById('start-date-input').value;
  const endDate = document.getElementById('end-date-input').value;
  const bicycleCity = document.querySelector('[data-city]').dataset.city;
  const category = document.getElementById('category-input').value;
  const bicycleCityUrl = bicycleCity.normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/'/g, '-').replace(/\s+/g, '-');

  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const boundsParams = `ne_lat=${ne.lat()}&ne_lng=${ne.lng()}&sw_lat=${sw.lat()}&sw_lng=${sw.lng()}`;

  let searchParams = '';
  if (category !== 'all') {
    searchParams += `bicycle_type=${category}`;
  }
  if (startDate && endDate) {
    searchParams += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const jsonUrl = mode === 'all' ? `/location-velo/${bicycleCityUrl}.json?${boundsParams}` : `/cities_filtered/${bicycleCityUrl}.json?${searchParams}&${boundsParams}`;
  const htmlUrl = mode === 'all' ? `/location-velo/${bicycleCityUrl}?${boundsParams}` : `/cities_filtered/${bicycleCityUrl}?${searchParams}&${boundsParams}`;

  try {
    let page = 1;
    let totalBicycles = 0;
    let bicycles = [];

    do {
    const responseJson = await fetch(`${jsonUrl}&page=${page}`);
    const currentBicycles = await responseJson.json();
    totalBicycles = parseInt(responseJson.headers.get('X-Total-Count'));

    if (currentBicycles.length === 0) break;

    currentBicycles.forEach(bike => {
      if (!allBicycles.some(existingBike => existingBike.id === bike.id)) {
        allBicycles.push(bike);
      }
    });

      page++;
    } while (allBicycles.length < totalBicycles);

    if (allBicycles.length === 0) {
      markers.forEach(marker => marker.setMap(null));
      markers = [];

      const message = "Aucun vélo disponible à ce lieu et à cette date... pour le moment !"; 
      const message_deux = "Essayez de rechercher un autre type de vélo ou d'autres dates ! N'hésitez pas à parler du site autour de vous afin de trouver votre bonheur la prochaine fois !";
      document.getElementById('bicycles-list').innerHTML = `<div class="text-center mt-5"><span><h4>${message}</h4></span>${message_deux}<p></div>`;
    } else {

      loadMarkers(allBicycles); // Mettre à jour les marqueurs pour tous les vélos chargés

      const responseHtml = await fetch(htmlUrl, {headers: {'Accept': 'text/html', 'X-Requested-With': 'XMLHttpRequest'}});
      const html = await responseHtml.text();
      document.getElementById('bicycles-list').innerHTML = html;
    }
    updateLoadMoreButtonVisibility(totalBicycles);

  } catch (error) {
    console.error('Error loading bicycles:', error);
    document.getElementById('bicycles-list').innerHTML = `<div><h4 class="text-center">Erreur lors du chargement des vélos. N'oubliez pas de sélectionner des dates afin de filtrer votre recherche !</h4></div>`;
  } finally {
    spinner.style.display = 'none'; // Masque le spinner
  }

  toggleDateAndDurationFields(mode === 'all');
}

function updateLoadMoreButtonVisibility(totalBicycles) {
  const loadMoreButton = document.getElementById('load-more');
  if (!loadMoreButton) return;

    // Si la recherche par localisation est active, vérifiez le nombre de vélos chargés
    if (totalBicycles < 11) {
      loadMoreButton.style.display = 'none';
    } else {
      loadMoreButton.style.display = 'block';
    }
}

document.addEventListener('turbo:load', function() {
  const cityMapElement = document.getElementById('city-map');
  if (!cityMapElement) return;
  
  const loadMoreButton = document.getElementById('load-more');

  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', async function() {
      const button = this;
      const page = button.getAttribute('data-page');
      const boundsParams = buildBoundsParams(); // Assurez-vous que cette fonction construit correctement les paramètres
      const categoryFilter = isSearchFormSubmitted ? document.getElementById('category-input').value : '';
      const bicycleCity = document.querySelector('[data-city]').dataset.city;
      const bicycleCityUrl = bicycleCity.normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/'/g, '-').replace(/\s+/g, '-');

      const htmlUrl = `/location-velo/${bicycleCityUrl}?page=${page}&${boundsParams}${categoryFilter ? `&bicycle_type=${categoryFilter}` : ''}`;

      try {
        const responseHtml = await fetch(htmlUrl, {
          headers: { 'Accept': 'text/vnd.turbo-stream.html', 'X-Requested-With': 'XMLHttpRequest' }
        });
        const html = await responseHtml.text();
        document.getElementById('bicycles-list').insertAdjacentHTML('beforeend', html);

        console.log("X-Final-Page:", responseHtml.headers.get('X-Final-Page'));

        const isFinalPage = responseHtml.headers.get('X-Final-Page') === 'true';
        console.log("Is final page:", isFinalPage);

        const responseJson = await fetch(`/location-velo/${bicycleCityUrl}.json?page=${page}&${boundsParams}${categoryFilter ? `&bicycle_type=${categoryFilter}` : ''}`);
        const newBicycles = await responseJson.json();

        allBicycles = allBicycles.concat(newBicycles); // Ajoutez les nouveaux vélos à la liste globale
        updateLoadMoreButtonVisibility(responseHtml);

        const nextPage = parseInt(page) + 1;
        if (!isFinalPage) {
          button.setAttribute('data-page', nextPage);
          sessionStorage.setItem('page', nextPage);
        } else {
          button.style.display = 'none'; // Si c'est la dernière page, retirez le bouton
        }
      } catch (error) {
        console.error('Error loading more bicycles:', error);
      }
    });
  }
});