import "@hotwired/turbo-rails";
import "./controllers";
import { initMap as initAutocompleteMap } from "./packs/autocompleteMap";
import { initIndexMap } from "./packs/index";
import { initSimpleMap } from "./packs/simpleMap";
import { estimateCost } from "./packs/estimate_cost";
import "./packs/backToTopBtn.js";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { French } from "flatpickr/dist/l10n/fr.js";
import "./packs/index.js";
import "./packs/adressField.js";
import"./packs/validationsForm.js"
import"./packs/validationFormEdit.js"
import"./packs/featureForm.js"
import"./packs/cookies.js"
import { initializeFlatpickr } from "./packs/flatpickr.js"

flatpickr.localize(French);

document.addEventListener("turbo:load", initializeFlatpickr);
document.addEventListener("turbo:render", initializeFlatpickr);

let mapsInitialized = {
  autocomplete: false,
  index: false,
  simple: false
};

window.initMap = function() {
  // Autocomplete map
  if (document.getElementById("location-input") && !mapsInitialized.autocomplete) {
    initAutocompleteMap();
    mapsInitialized.autocomplete = true;
  }

  // Simple map (gmp-map)
  if (document.getElementById("gmp-map") && !mapsInitialized.simple) {
    initSimpleMap();
    mapsInitialized.simple = true;
  }

  // Generic maps based on specific IDs
  const mapConfigs = [
    { id: "index-map", zoom: 6 },
    { id: "category-map", zoom: 6 },
    { id: "city-map", zoom: 9 },
    { id: "location-map", zoom: 9 },
  ];

  mapConfigs.forEach(({ id, zoom }) => {
    if (document.getElementById(id) && !mapsInitialized[id]) {
      initIndexMap({ mapElementId: id, defaultZoom: zoom });
      mapsInitialized[id] = true;
    }
  });
}

// Gérer la réinitialisation lors de la navigation Turbo
document.addEventListener('turbo:load', () => {
  if (window.google && window.google.maps) {
    window.initMap();
  }
});

function initConditionalScripts() {
  if (document.getElementById('rental_form')) {
    estimateCost();
  }
}

document.addEventListener('turbo:load', function () {
  initConditionalScripts();
});

document.addEventListener('turbo:before-visit', function () {
  mapsInitialized = {
    autocomplete: false,
    index: false,
    simple: false
  };
});

document.addEventListener('DOMContentLoaded', function () {
  const closeButton = document.querySelector('.close-alert');
  const stickyAlert = document.querySelector('.sticky-alert');

  if (closeButton && stickyAlert) {
    closeButton.addEventListener('click', function () {
      stickyAlert.style.transition = 'opacity 0.5s ease-in-out';
      stickyAlert.style.opacity = '0';

      // Supprime l'alerte après la transition
      setTimeout(() => {
        stickyAlert.remove();
      }, 500);
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const section = document.getElementById('scroll-section');
  const sectionTwo = document.getElementById('scroll-section-2');

  if (section || sectionTwo) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Ajoute la classe 'visible' lorsque l'élément entre dans la vue
          entry.target.classList.add('visible');
          observer.unobserve(entry.target); // Arrête d'observer l'élément après qu'il soit visible
        }
      });
    }, {
      threshold: 0.5 // L'élément doit être à 50% visible pour déclencher l'animation
    });

    if (section) {
      observer.observe(section); // Commence à observer section si elle existe
    }

    if (sectionTwo) {
      observer.observe(sectionTwo); // Commence à observer sectionTwo si elle existe
    }
  }
});


document.addEventListener('DOMContentLoaded', function () {
  // Vérifie si le carousel des témoignages est présent
  const testimonialsCarousel = document.getElementById("testimonialsCarousel");

  if (testimonialsCarousel && typeof $.fn.swipe === "function") {
    $("#testimonialsCarousel").swipe({
      swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');
      },
      allowPageScroll:"vertical"
    });
  }
});

import "@hotwired/turbo-rails";