import { map } from "./index";

function isDayDisabled(date, reservedDates) {
  const dateString = flatpickr.formatDate(date, "Y-m-d");
  const reservation = reservedDates.find(r => r.date === dateString);
  if (!reservation) return false;
  return reservation.disabledHours.some(([start, end]) => start < 14 && end >= 14);
}


export function initializeFlatpickr() {
  const startDateInput = document.getElementById("start-date-input");
  const endDateInput = document.getElementById("end-date-input");

  if (!startDateInput || !endDateInput) {
    // Si l'un des éléments n'existe pas, sortir de la fonction
    return;
  }
  
  const reservedDates = JSON.parse(startDateInput.getAttribute('data-reserved-dates') || '[]');

  const startFlatpickr = flatpickr(startDateInput, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate: "today",
      disable: [
          function(date) { return isDayDisabled(date, reservedDates); }
      ],
      minuteIncrement: 60,
      time_24hr: true,
      onChange: function(selectedDates, dateStr, instance) {
        if (endDateInput._flatpickr) {
            endDateInput._flatpickr.set('minDate', dateStr);
        }
      },
      onDayCreate: function(dObj, dStr, fp, dayElem) {
        dayElem.addEventListener("click", function() {
          setTimeout(() => {
            fp.close(); // Fermer le sélecteur de date de début
            if (endDateInput._flatpickr) {
              endDateInput._flatpickr.open(); // Ouvrir le sélecteur de date de fin
            }
          }, 0);
        });
      }  
  });

  const endFlatpickr = flatpickr(endDateInput, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate: "today",
      disable: [
          function(date) { return isDayDisabled(date, reservedDates); }
      ],
      minuteIncrement: 60,
      time_24hr: true,
      onChange: function(selectedDates, dateStr, instance) {
      },
      onDayCreate: function(dObj, dStr, fp, dayElem) {
        dayElem.addEventListener("click", function() {
          setTimeout(() => {
            fp.close(); // Fermer le sélecteur de date de fin
          }, 0);
        });
      }  
  });


  // Ajouter des gestionnaires d'événements pour les boutons de réinitialisation
  const resetStartDateButton = document.getElementById("reset-start-date");
  const resetEndDateButton = document.getElementById("reset-end-date");
  const resetCityButton = document.getElementById("reset-city");
  const cityInput = document.getElementById("city-input");
  const DEFAULT_LAT = 47.081012;
  const DEFAULT_LNG = 2.398782;
  const DEFAULT_ZOOM_LEVEL = 6;
  
  
  if (resetStartDateButton) {
    resetStartDateButton.addEventListener("click", function() {
      startFlatpickr.clear();
      if (endDateInput._flatpickr) {
        endDateInput._flatpickr.set('minDate', "today");
      }
    });
  }
  
  if (resetEndDateButton) {
    resetEndDateButton.addEventListener("click", function() {
      endFlatpickr.clear();
    });
  }
  
  if (resetCityButton) {
    resetCityButton.addEventListener("click", function() {
      cityInput.value = '';
  
      if (map) {
        map.setCenter({ lat: DEFAULT_LAT, lng: DEFAULT_LNG }); // Coordonées par défaut de la carte
        map.setZoom(DEFAULT_ZOOM_LEVEL);
      }
    });
  }
}  