import { initializeFormValidation, getDescriptionForCondition, displayValidationMessage } from './featureForm';

const formSelector = '#edit-bicycle-form';
const edition_form = document.querySelector(formSelector);

if (edition_form) {
    initializeFormValidation(formSelector);
}

const degressiveRates = {
    vélo_de_ville: { weekly: 25 },
    vélo_électrique: { weekly: 25 },
    vélo_de_randonnées: { weekly: 20 },
    vélo_tout_terrain: { weekly: 25 },
    bmx: { weekly: 15 },
    tandem: { weekly: 20 },
    vélo_cargo: { weekly: 20 },
    vélo_de_route: { weekly: 15 },
    vélo_pignon_fixe: { weekly: 25 },
    vélo_longtail: { weekly: 20 },
    gravel: { weekly: 20 },
    vélo_enfant: { weekly: 25 },
    autre: { weekly: 15 },
    porte_vélo: { weekly: 15 },
    autres_accessoires: { weekly: 15 },
    parking: { weekly: 15 }
  };

  document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector('#edit-bicycle-form')) {
      initialize();
    }
  });
  
  document.addEventListener("turbo:load", function() {
    if (document.querySelector('#edit-bicycle-form')) {
      initialize();
    }
  });
  
  document.addEventListener("turbo:render", function() {
    if (document.querySelector('#edit-bicycle-form')) {
      initialize();
    }
  });

function initialize() {
  const addButton = document.getElementById("add_accessory");
  if (addButton) {
    const options = JSON.parse(document.getElementById("accessoryOptions").getAttribute("data-options"));
    addButton.addEventListener("click", () => {
      const selectedAccessories = Array.from(document.querySelectorAll("select[name^='bicycle[accessories_attributes]'][name$='[accessory_type]']"))
                                        .map(select => select.value);

      const newAccessoryId = new Date().getTime();
      const newAccessoryField = document.createElement("div");
      
      // Filtrer les options pour exclure celles déjà sélectionnées
      const filteredOptions = options.filter(([name, value]) => !selectedAccessories.includes(value));

      let selectHTML = `<select name="bicycle[accessories_attributes][${newAccessoryId}][accessory_type]" id="bicycle_accessories_attributes_${newAccessoryId}_accessory_type" class="form-select">`;
      selectHTML += `<option value="">Sélectionnez un accessoire</option>`;
      filteredOptions.forEach(([name, value]) => {
        selectHTML += `<option value="${value}">${name}</option>`;
      });
      selectHTML += `</select>`;

      newAccessoryField.innerHTML = `<label for="bicycle_accessories_attributes_${newAccessoryId}_accessory_type">Accessoire</label>` + selectHTML;

      document.getElementById("new_accessory").appendChild(newAccessoryField);
    });
  }

    const priceField = document.querySelector("#price_per_hour");
    const categoryField = document.querySelector("#bicycle_bicycle_type");
    const priceForHalfDayField = document.querySelector("#price_for_half_day");
    const priceFor2DaysField = document.querySelector("#price_for_2_days");
    const priceFor1WeekField = document.querySelector("#price_for_1_week");
    const priceFor2WeeksField = document.querySelector("#price_for_2_weeks");
    const priceFor1MonthField = document.querySelector("#price_for_1_month");
    const weeklyRateField = document.querySelector("#weekly_rate");

    const initialPrices = {
      price_for_half_day: priceForHalfDayField.value,
      price_for_2_days: priceFor2DaysField.value,
      price_for_1_week: priceFor1WeekField.value,
      price_for_2_weeks: priceFor2WeeksField.value,
      price_for_1_month: priceFor1MonthField.value,
      weekly_rate: weeklyRateField.value
    };

    function updatePrices() {
      const pricePerDay = parseFloat(priceField.value);
      const category = categoryField.value;
      const customWeeklyRate = parseFloat(weeklyRateField.value);
  
      if (!isNaN(pricePerDay) && category && degressiveRates[category]) {
        const rates = {
          weekly: customWeeklyRate || degressiveRates[category].weekly
        };
  
        const priceForHalfDay = calculateHalfDayPrice(pricePerDay);
        const priceFor2Days = calculateDiscountedPrice(pricePerDay, 2, rates);
        const priceFor1Week = calculateDiscountedPrice(pricePerDay, 7, rates);
        const priceFor2Weeks = calculateDiscountedPrice(pricePerDay, 14, rates);
        const priceFor1Month = calculateMonthlyPrice(pricePerDay, rates);
  
        priceForHalfDayField.value = priceForHalfDay.toFixed(1);
        priceFor2DaysField.value = priceFor2Days.toFixed(1);
        priceFor1WeekField.value = priceFor1Week.toFixed(1);
        priceFor2WeeksField.value = priceFor2Weeks.toFixed(1);
        priceFor1MonthField.value = priceFor1Month.toFixed(1);

        initialPrices.price_for_half_day = priceForHalfDayField.value;
        initialPrices.price_for_2_days = priceFor2DaysField.value;
        initialPrices.price_for_1_week = priceFor1WeekField.value;
        initialPrices.price_for_2_weeks = priceFor2WeeksField.value;
        initialPrices.price_for_1_month = priceFor1MonthField.value;

        updateWeeklyRateSuggestion();
      }
    }

    function updateField(field, recommendedValue) {
      const suggestionSpan = document.querySelector(`#suggestion-${field.id}`);
    
      const currentValue = parseFloat(field.value);
      const recommendedPrice = parseFloat(recommendedValue);

      console.log(`Current value: ${currentValue}, Recommended price: ${recommendedPrice}`); 
    
      // Afficher "prix conseillé" uniquement si le prix actuel est égal au prix recommandé
      if (currentValue === recommendedPrice) {
        suggestionSpan.style.display = "inline";
      } else {
        suggestionSpan.style.display = "none";
      }
    }

  function updateWeeklyRateSuggestion() {
    const category = categoryField.value;
    const initialRate = degressiveRates[category]?.weekly;

    if (initialRate !== undefined && parseFloat(weeklyRateField.value) !== parseFloat(initialRate)) {
      document.querySelector("#suggestion-weekly_rate").style.display = "none";
      document.querySelector('[data-target="weekly_rate"]').style.display = "inline";
    } else {
      document.querySelector("#suggestion-weekly_rate").style.display = "inline";
      document.querySelector('[data-target="weekly_rate"]').style.display = "none";
    }
  }

  function resetToRecommendedPrice(event) {
    const targetFieldId = event.target.closest("button").dataset.target;
    const targetField = document.querySelector(`#${targetFieldId}`);
    const pricePerDay = parseFloat(priceField.value);
    const category = categoryField.value;
    const customWeeklyRate = parseFloat(weeklyRateField.value);

    if (!isNaN(pricePerDay) && category && degressiveRates[category]) {
      const rates = {
        weekly: customWeeklyRate || degressiveRates[category].weekly
      };

      let recommendedPrice;
      switch (targetFieldId) {
        case "price_for_half_day":
          recommendedPrice = calculateHalfDayPrice(pricePerDay);
          break;
        case "price_for_2_days":
          recommendedPrice = calculateDiscountedPrice(pricePerDay, 2, rates);
          break;
        case "price_for_1_week":
          recommendedPrice = calculateDiscountedPrice(pricePerDay, 7, rates);
          break;
        case "price_for_2_weeks":
          recommendedPrice = calculateDiscountedPrice(pricePerDay, 14, rates);
          break;
        case "price_for_1_month":
          recommendedPrice = calculateMonthlyPrice(pricePerDay, rates);
          break;
        case "weekly_rate":
          recommendedPrice = degressiveRates[category].weekly;
          break;
        default:
          return;
      }

      targetField.value = recommendedPrice.toFixed(1);
      updateField(targetField, recommendedPrice.toFixed(1));
    }
  }

  document.querySelectorAll(".recommended-price").forEach(button => {
    button.addEventListener("click", resetToRecommendedPrice);
  });

  if (priceField && categoryField) {
    priceField.addEventListener("input", updatePrices);
    categoryField.addEventListener("change", () => {
      updateWeeklyRate();
      updatePrices();
    });
  }
  
  if (weeklyRateField) {
    weeklyRateField.addEventListener("input", updatePrices);
  }

  [priceForHalfDayField, priceFor2DaysField, priceFor1WeekField, priceFor2WeeksField, priceFor1MonthField].forEach(field => {
    field.addEventListener("input", event => {
      const recommendedPrice = calculateRecommendedPriceForField(event.target.id); // Fonction hypothétique pour obtenir le prix recommandé
      updateField(event.target, recommendedPrice);
      const { isValid, errorMessage } = validatePrices();
      displayValidationMessage(isValid, errorMessage);
    });
  });

  function calculateRecommendedPriceForField(fieldId) {
    // Implémentez cette fonction pour retourner le prix recommandé pour chaque champ en fonction de son ID
    const pricePerDay = parseFloat(priceField.value); // Assurez-vous que c'est un nombre
  
    switch (fieldId) {
      case "price_for_half_day":
        return calculateHalfDayPrice(pricePerDay);
      case "price_for_2_days":
        return calculateDiscountedPrice(pricePerDay, 2, { weekly: parseFloat(weeklyRateField.value) });
      case "price_for_1_week":
        return calculateDiscountedPrice(pricePerDay, 7, { weekly: parseFloat(weeklyRateField.value) });
      case "price_for_2_weeks":
        return calculateDiscountedPrice(pricePerDay, 14, { weekly: parseFloat(weeklyRateField.value) });
      case "price_for_1_month":
        return calculateMonthlyPrice(pricePerDay, { weekly: parseFloat(weeklyRateField.value) });
      case "weekly_rate":
        return degressiveRates[categoryField.value]?.weekly || 0;
      default:
        return null;
    }
  }

  const selectElement = document.getElementById('conditionSelect');
  if (selectElement) {
    selectElement.addEventListener('change', function() {
      const selectedCondition = this.value;
      const description = getDescriptionForCondition(selectedCondition);
      document.getElementById('conditionDescription').innerHTML = '<div class="accordion-body">' + description + '</div>';
    });

    selectElement.dispatchEvent(new Event('change'));
  }

  document.querySelectorAll('.remove-image-link').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const imageId = link.getAttribute('data-image-id');
      const hiddenField = document.getElementById(`remove_image_${imageId}`);
      hiddenField.value = imageId;
      link.closest('.existing-image').style.display = 'none';
    });
  });
}

const form = document.querySelector("#edit-bicycle-form");
if (form) {
  form.addEventListener("submit", function(event) {
    const latitude = document.getElementById('latitude-input').value;
    const longitude = document.getElementById('longitude-input').value;

    // Vérification des coordonnées GPS
    if (!latitude || !longitude) {
      event.preventDefault(); // Empêche la soumission du formulaire
      alert('Veuillez sélectionner une adresse dans les propositions pour inclure les coordonnées GPS.');
      return; // Assure que la soumission du formulaire est arrêtée
    }

    // Validation des prix
    const { isValid, errorMessage } = validatePrices();

    if (!isValid) {
      event.preventDefault(); // Empêche la soumission du formulaire
      displayValidationMessage(isValid, errorMessage); // Affiche le message d'erreur
      alert('Veuillez vérifier la cohérence des prix proposés.');
    } else {
      displayValidationMessage(isValid, ""); // Efface le message d'erreur
    }
  });
}



function calculateMonthlyPrice(basePrice, rates) {
  const priceForFirstWeek = calculateDiscountedPrice(basePrice, 7, rates);
  const priceForSecondWeek = Math.ceil(7 * basePrice * 0.5);
  const priceFor2Weeks = priceForFirstWeek + priceForSecondWeek;

  if (basePrice < 9) {
    return parseFloat((priceFor2Weeks * 2 * 0.85).toFixed(1))
  } else {
    return Math.ceil(priceFor2Weeks * 2 * 0.85);
  }
}

function calculateHalfDayPrice(basePrice) {
  const HOURS_IN_HALF_DAY = 12;
  if (basePrice < 9) {
    return parseFloat(((HOURS_IN_HALF_DAY * basePrice / 24) * 1.4).toFixed(1))
  } else {
    return Math.ceil((HOURS_IN_HALF_DAY * basePrice / 24) * 1.4);
  }
}

function calculateDiscountedPrice(basePrice, days, rates) {
  basePrice = parseFloat(basePrice); // Convertir en nombre
  if (isNaN(basePrice)) return 0;

  let totalPrice = 0;

  if (days > 1) {
    let currentDayPrice; 
    
    if (basePrice < 9) {
      currentDayPrice = parseFloat((basePrice).toFixed(1));
    } else {
      currentDayPrice = Math.ceil(basePrice);
    }

    for (let i = 0; i < Math.min(7, days); i++) {
      totalPrice += currentDayPrice;
      if (rates && rates.weekly !== undefined) {
        let weeklyRate = rates.weekly / 100;
        if (basePrice < 9) {
          currentDayPrice = parseFloat(Math.ceil(currentDayPrice * (1 - weeklyRate) * 10) / 10);
        } else {
          currentDayPrice = Math.ceil(currentDayPrice * (1 - weeklyRate));
        }
      }
    }

    if (days > 7) {
      if (basePrice < 9) {
        totalPrice += (days - 7) * basePrice * 0.5;
      } else {
        totalPrice += Math.ceil((days - 7) * basePrice * 0.5);
      }
    }
  } else {
    totalPrice = basePrice;
  }

  return totalPrice;
}


function validatePrices() {
  const oneDayPriceField = document.querySelector("#price_per_hour");
  const priceForHalfDayField = document.querySelector("#price_for_half_day");
  const priceFor2DaysField = document.querySelector("#price_for_2_days");
  const priceFor1WeekField = document.querySelector("#price_for_1_week");
  const priceFor2WeeksField = document.querySelector("#price_for_2_weeks");
  const priceFor1MonthField = document.querySelector("#price_for_1_month");
  const halfDayPrice = parseFloat(priceForHalfDayField.value);
  const oneDayPrice = parseFloat(oneDayPriceField.value);
  const twoDaysPrice = parseFloat(priceFor2DaysField.value);
  const oneWeekPrice = parseFloat(priceFor1WeekField.value);
  const twoWeeksPrice = parseFloat(priceFor2WeeksField.value);
  const oneMonthPrice = parseFloat(priceFor1MonthField.value);

  let isValid = true;
  let errorMessage = "";

  if (!priceForHalfDayField.value || isNaN(halfDayPrice) || halfDayPrice <= 0) {
    isValid = false;
    errorMessage += "Veuillez entrer un prix valide pour une demi-journée.\n";
  }
  if (!oneDayPriceField.value || isNaN(oneDayPrice) || oneDayPrice <= 0) {
    isValid = false;
    errorMessage += "Veuillez entrer un prix valide pour un jour.\n";
  }
  if (!priceFor2DaysField.value || isNaN(twoDaysPrice) || twoDaysPrice <= 0) {
    isValid = false;
    errorMessage += "Veuillez entrer un prix valide pour deux jours.\n";
  }
  if (!priceFor1WeekField.value || isNaN(oneWeekPrice) || oneWeekPrice <= 0) {
    isValid = false;
    errorMessage += "Veuillez entrer un prix valide pour une semaine.\n";
  }
  if (!priceFor2WeeksField.value || isNaN(twoWeeksPrice) || twoWeeksPrice <= 0) {
    isValid = false;
    errorMessage += "Veuillez entrer un prix valide pour deux semaines.\n";
  }
  if (!priceFor1MonthField.value || isNaN(oneMonthPrice) || oneMonthPrice <= 0) {
    isValid = false;
    errorMessage += "Veuillez entrer un prix valide pour un mois.\n";
  }

  if (oneDayPrice < halfDayPrice) {
    isValid = false;
    errorMessage += "Le prix pour une demi journée ne peut pas être supérieur au prix pour 1 jour.\n";
  }
  if (twoDaysPrice < oneDayPrice) {
    isValid = false;
    errorMessage += "Le prix pour 2 jours ne peut pas être inférieur au prix pour 1 jour.\n";
  } 
  if (oneWeekPrice < twoDaysPrice) {
    isValid = false;
    errorMessage += "Le prix pour 1 semaine ne peut pas être inférieur au prix pour 2 jours.\n";
  }
  if (twoWeeksPrice < oneWeekPrice) {
    isValid = false;
    errorMessage += "Le prix pour 2 semaines ne peut pas être inférieur au prix pour 1 semaine.\n";
  }
  if (oneMonthPrice < twoWeeksPrice) {
    isValid = false;
    errorMessage += "Le prix pour 1 mois ne peut pas être inférieur au prix pour 2 semaines.\n";
  }

  return { isValid, errorMessage };
}
